.header {
  grid-area: head;
  background-color: $color-grey-light-1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__logo {
    margin-left: 4rem;
    display: block;
    width: 10rem;
    height: 10rem;
    background-image: url(../img/logo.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.search {
  background-color: #fff;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  padding-left: 3rem;
  transition: all 0.3s;

  &:focus-within {
    transform: translateY(-2px);
    box-shadow: 0 0.7rem 3rem rgba($color-grey-dark-1, 0.08);
  }

  &__field {
    border: none;
    background: none;
    font-family: inherit;
    color: inherit;
    font-size: 1.7rem;
    width: 30rem;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $color-grey-light-3;
    }

    @media only screen and (max-width: $bp-medium) {
      width: auto;

      &::placeholder {
        color: #ecc9a9;
      }
    }
  }

  &__btn {
    font-weight: 600;
    font-family: inherit;
  }
}

.nav {
  align-self: stretch;
  margin-right: 2.5rem;

  &__list {
    list-style: none;
    display: flex;
    height: 100%;
  }

  &__item {
    position: relative;
  }

  &__btn {
    height: 100%;
    font-family: inherit;
    color: inherit;
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 1.5rem;
    transition: all 0.3s;

    display: flex;
    align-items: center;

    svg {
      height: 2.4rem;
      width: 2.4rem;
      fill: $color-primary;
      margin-right: 0.7rem;
      transform: translateY(-1px);
    }

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: $color-grey-light-2;
    }
  }
}

.bookmarks {
  padding: 1rem 0;
  position: absolute;
  right: -2.5rem;
  z-index: 10;
  width: 40rem;
  background-color: #fff;
  box-shadow: 0 0.8rem 5rem 2rem rgba($color-grey-dark-1, 0.1);

  visibility: hidden;
  opacity: 0;
  transition: all 0.5s 0.2s;

  &__list {
    list-style: none;
  }

  &__field {
    cursor: pointer;
    padding: 0 4rem;

    display: flex;
    align-items: center;
    height: 100%;
    transition: all 0.3s;

    &:hover {
      background-color: $color-grey-light-2;
    }
  }

  &:hover,
  .nav__btn--bookmarks:hover + & {
    visibility: visible;
    opacity: 1;
  }
}

@media screen and (max-width: 680px) {
  .header {
    &__logo {
      margin: 0.5rem;
      width: 8rem;
      background-image: url(../img/favicon.png);
      padding-right: 0.4rem;
    }
  }

  .search {
    &:focus-within {
      box-shadow: 0 0.7rem 3rem rgba($color-grey-dark-1, 0.15);
    }
    &__field {
      font-size: 1.5rem;
      max-width: 13.5rem;
    }

    &__btn span {
      display: none;
    }
  }

  .btn {
    padding: 2rem 1.5rem;
    font-size: 1rem;
    border-radius: 100%;

    svg {
      height: 2.5rem;
      width: 2.5rem;
      position: relative;
      left: 3;
    }
  }
}

@media screen and (max-width: 550px) {
  .header {
    &__logo {
      margin: 0 1rem;
      display: flex;
      min-width: 6rem;
      background-image: url(../img/favicon.png);
    }
  }

  .nav {
    margin-left: 1rem;
    &__btn {
      padding: 2px;
      span {
        display: none;
      }

      &__icon {
        margin: 0rem;
        padding: 0rem;
      }

      svg {
        height: 3.5rem;
        width: 3.5rem;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        background-color: $color-grey-light-2;
      }
    }
  }
}
