.search-results {
  padding: 0rem 0rem;
  display: flex;
  flex-direction: column;
}

.results {
  list-style: none;
  margin-bottom: 2rem;
}

.copyright2 {
  display: none;
}

.pagination {
  margin-top: auto;
  padding: 0 3.5rem;

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  &__btn {
    &--prev {
      float: left;
    }
    &--next {
      float: right;
    }
  }
}

.copyright {
  color: $color-grey-dark-2;
  font-size: 1.2rem;
  padding: 0 3.5rem;
  margin-top: 4rem;

  .twitter-link:link,
  .twitter-link:visited {
    color: $color-grey-dark-2;
  }
}

@media screen and (max-width: 750px) {
  .copyright {
    display: none;
  }
  .copyright2 {
    display: block;
  }

  .search-results {
    margin-bottom: 2rem;
  }
}
